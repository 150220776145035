export const DsImageDefaultState = {
    stage: 'LOADING'
};
export const DEFULT_ERROR_ICON_PROPS = {
    fontSize: 'mild',
    className: 'ri-image-2-line'
};
export const INNER_COMPONENT_STYLE = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%'
};
